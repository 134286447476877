import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isAllow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public authTokenKey = 'matlop_token';
    public allowTokenKey = 'isAllow';
    DecodedToken: any;
    constructor() {
        this.checkToken();
        this.checkAllow();
    }

    private checkToken(): void {
        const token = localStorage.getItem(this.authTokenKey);
        if (token) {
            console.log(localStorage.getItem(this.authTokenKey));
            this.loggedIn.next(true);
        }
    }
    private checkAllow(): void {
        
        const token = localStorage.getItem(this.allowTokenKey);
        
        if (token) {
            this.isAllow.next(true);
        }
    }
    getDecodedAccessToken(): any {
        try {
            this.DecodedToken = jwtDecode(this.getAuthToken());
            return jwtDecode(this.getAuthToken())
        } catch (Error) {
            return null;
        }
    }

    isLoggedid$(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }
    isAllow$(): Observable<boolean> {
        console.log("isAllow entered");
        return this.loggedIn.asObservable();
    }

    logout(): void {
        localStorage.removeItem(this.authTokenKey);
        localStorage.removeItem(this.allowTokenKey);
        this.loggedIn.next(false);
        this.isAllow.next(false);
    }

    getAuthToken(): string | null {
        return localStorage.getItem(this.authTokenKey);
    }

}
