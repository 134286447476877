import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
  ],
  exports:[
    TranslateModule
  ],
  providers:[TranslateService]
})
export class SharedModule { }
