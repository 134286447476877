import { Component } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    title: string = 'Matolp';
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private cookieService: CookieService,
        private translate: TranslateService
    ) {
        this.cookieService.set('myCookie', 'cookieValue', null, null, null, false, 'Strict');
        let lang = localStorage.getItem('language') || 'en'
        translate.setDefaultLang(lang);
        translate.use(lang);
        if (lang == 'ar') {
            document.getElementById('body').classList.remove('direction-ltr')
            document.getElementById('body').classList.add('direction-rtl')
        } else {
            document.getElementById('body').classList.remove('direction-rtl')
            document.getElementById('body').classList.add('direction-ltr')
        }
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}