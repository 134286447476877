<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>404</h2>
            <ul>
                <li><a routerLink="/" translate>bisc.home </a></li>
                <li class="active">404</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="error-page">
        <img src="assets/images/404.gif" alt="error">
        <h3 translate>bisc.error404</h3>
        <p translate>bisc.error404_des</p>
        <a routerLink="/" class="box-btn" translate>bisc.back_to_home</a>
    </div>
</div>