import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/pages/error/error.component';




const routes: Routes = [
    {path: '', loadChildren: () => import('./components/pages/layout-wepsite/layout-wepsite.module').then(m => m.LayoutWepsiteModule)},
    // {path:'dashboard' , loadChildren: () => import('./components/pages/layout-dashboard/layout-dashboard.module').then(m => m.LayoutDashboardModule)},
    // Here add new pages component

    
    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration:'top'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }